body {
  min-width: unset;
}

#fo-conversation-popup-wrapper {
  z-index: 10;
}

#root,
.App {
  height: 100%;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden !important;
}


.App::-webkit-scrollbar {
  display: none;
}

/*Navigation Styles*/
.navigation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  opacity: 0;
  background-color: black;
  pointer-events: none;
  transition: opacity 0.3s;
  z-index: 15;
}

.navigation-overlay h2 {
  cursor: pointer;
}

.navigation-overlay.active {
  pointer-events: auto;
  opacity: 1;
}

.nav-divider {
  position: relative;
  top: 55px;
  margin: 0;
}

.nav-bar {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 20;
  height: 55px;
  background-color: transparent;
  /* border-bottom: 1px solid black; */
}

.nav-bar.open-nav {
  background-color: black;
  transition: 0.25s;
}

.nav-bar.home-route {
  background-color: transparent;
  border: none;
}

.burger-box__container {
  width: 65px;
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: white;
  height: 100%;
  transition: all 0.3s;
}

.burger-box__container.clear {
  background-color: transparent;
}

.burger-box {
  z-index: 20;
  position: absolute;
  width: 56px;
  height: 50px;
  transform: scale(0.6);
  top: 5px;
  right: 5px;
  cursor: pointer;
}

#burger {}

#burger span {
  display: block;
  position: absolute;
  height: 5px;
  width: 50%;
  background: black;
  opacity: 1;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#burger.turned-white span {
  background: white;
}

#burger.open span {
  background: white;
}

#burger span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#burger span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#burger span:nth-child(1),
#burger span:nth-child(2) {
  top: 0px;
}

#burger span:nth-child(3),
#burger span:nth-child(4) {
  top: 18px;
}

#burger span:nth-child(5),
#burger span:nth-child(6) {
  top: 36px;
}

#burger.open span:nth-child(1),
#burger.open span:nth-child(6) {
  transform: rotate(45deg);
}

#burger.open span:nth-child(2),
#burger.open span:nth-child(5) {
  transform: rotate(-45deg);
}

#burger.open span:nth-child(1) {
  left: 5px;
  top: 10px;
}

#burger.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 10px;
}

#burger.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#burger.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#burger.open span:nth-child(5) {
  left: 5px;
  top: 27px;
}

#burger.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 27px;
}


/*End Navigation styles*/


.profileDiv {
  text-align: center;
  padding-top: 10vh;
  /* border: dashed pink */
}

.textContainer {
  /* text-align: center; */
}

.aboutText {
  padding-top: 5vh;
  padding-left: 10vw;
  padding-right: 10vw;
}

.pleasure-header {
  margin-bottom: 0px;
}

.contactDiv {
  text-align: center;
  padding-top: 1vh;
  padding-bottom: 3vh;
}

.contactDiv h1 {
  margin-top: 18px;
}

.card {
  width: 80%
}

.projectCard a {
  padding: 5px
}

.contentContainer {
  position: relative;
  top: 10vh;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  height: 300px;
}

.book-call-button {
  color: white;
  display: flex;
  justify-content: center;
}

.book-call-button h3 {
  background-color: #4182C4;
  padding: 10px 10px;
  border-radius: 15px;
  width: 75%;
}

.waitlist-button {
  background-color: #4182C4;
  padding: 10px 10px;
  border-radius: 15px;
  color: white;
  border: none;
  font-weight: 400;
  font-size: 18px;
}


.ui.menu {
  display: flex;
}

.ui.menu .item {
  flex: 1;
  justify-content: center;
}

.optin-page {
  height: 100%;
  background-color: rgba(94, 204, 255, 0.6);
}

.optin-header {
  height: 100%;
  width: 100%;
  background-image: url('./images/beach-two.jpeg');
  background-repeat: no-repeat;
  background-position: center top;
}

.optin-modal {
  text-align: center !important;
  width: 400px !important;
}

.optin-form {
  border: none;
}

.optin-header {
  height: 700px;
  width: 100%;
  background-image: url('./images/beach-two.jpeg');
  background-repeat: no-repeat;
  background-position: -100px -100px;
  display: flex;
}

.person-box {
  flex: 0.8;
  max-width: 440px;
}

.person-info {
  flex: 1.2;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}

.person-name {
  color: white;
  font-size: 30px;
}

.person-credentials {
  text-align: center
}

.person-info div {
  /*border: 1px solid green;*/
}

.person-credentials {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  color: black;
}

.person-credentials p {
  background-color: rgba(255, 255, 255, 0.7)
}

.button-container {
  flex: 0.7;
}

.book-btn {
  color: white;
  background-color: #4182C4;
  border: none;
  border-radius: 15px;
  padding: 10px;
  font-size: 18px;
}

.optin-footer {
  text-align: center;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  background-color: white;
}

.optin-logo {
  height: 200px;
  width: 200px;
}

.bl-container,
.bl-container div {
  /*border: 1px solid white;*/
}

.blh-header {
  display: flex;
  flex-direction: column;
  background-color: black;
  padding-bottom: 20px;
}

.bl-container {
  height: -moz-fit-content;
  height: fit-content;
  background-color: black;
  font-family: Titillium Web, Arial, sans-serif;
  font-weight: 200;
  scrollbar-width: none;
}

.blh-image {
  height: 450px;
  text-align: center;
}

.book-img {
  height: 100%;
}

.blh-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blh-description {
  color: white;
  text-align: center;
  line-height: 24px;
  padding: 15px;
  font-size: 18px;
}

.bl-button-container {
  display: flex;
  justify-content: center;
}

.bl-button-container button {
  background-color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
  color: black
}

.bl-body {
  text-align: center;
  border-left: 20px solid black;
  border-right: 20px solid black;
}

.reviews-container {
  background-color: white;
  padding-top: 10px;
}

.review {
  display: flex;
  padding: 7px 5px;
  border-bottom: 1px solid black;
}

.rev-left-container {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rev-description {
  flex: 0.7;
  padding: 5px;
}

.bl-quotes {
  background-color: black;
  color: white;
  padding: 10px
}

.bl-quotes p {
  font-size: 18px;
}

.final-cta {
  display: flex;
  flex-direction: column;
  height: 130px;
  justify-content: space-around;
  border-bottom: 15px solid black;
  align-items: center;
  background-color: black;
}

.final-cta a {
  border: 1px solid;
  width: 300px;
  background-color: white;
  color: black;
  font-size: 18px;
  border-radius: 10px;
  font-weight: 400;
  padding: 5px;
}

@media screen and (min-width: 600px) {
  .blh-header {
    flex-direction: row;
  }

  .blh-description {
    line-height: 28px;
  }
}

@media screen and (min-width: 850px) {

  .blh-description-container {
    gap: 40px;
  }

  .blh-image {
    height: 600px;
  }

  .blh-description {
    font-size: 24px;
    line-height: 35px
  }
}


/*Meditation/method page*/
button.meditation-toggle {
  margin: 5px 0 20px;
  background-color: #4183C4;
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
}